<template>
  <div class="main">
    <Loading
      :active.sync="isLoading"
      :is-full-page="true"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    Checking Download Link
    <div v-if="isDocumentLinkValid === 'false'">
      <h2>Sorry, the document link was invalid.</h2>
      <div class="errors">
        <ul class="vasion-ul">
          <li v-for="(error, index) in errors" :key="index" :field="error">
            {{ error }}
          </li>
        </ul>
      </div>
    </div>
    <div v-else-if="isDocumentLinkValid === 'true'">
      <h2>Success! The document link is valid!</h2>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { testIsGuid } from '../../store/helperModules/common.module';

export default {
  name: 'ThePublicDocumentGateway',
  components: {
    Loading,
  },
  data: function () {
    return {
      documentGuid: '',
      encryptedUserName: '',
      errors: [],
      isDocumentLinkValid: '',
      isLoading: false,
      isSignedIn: false,
      loaderBackgroundColor,
      loaderColor,
    }
  },
  async created() {
    this.$store.dispatch('document/resetStoreData')
    if (this.$route.params.documentID) {
      this.documentGuid = this.$route.params.documentID
      this.encryptedUserName = this.$route.params.encryptedUserName // Optional

      this.isLoading = true
      await this.runDocumentLinkChecks()
      this.isLoading = false
    }
  },
  methods: {
    async authenticateDefaultLinkUser() {
      if (this.encryptedUserName && this.encryptedUserName !== '') {
        const credentials = { Username: this.encryptedUserName, Password: 'pw' }
        await this.$store.dispatch('common/authenticateNoPassword', credentials)
        if (this.$store.state.common.isSignedIn) {
          this.isSignedIn = true
        }
      }
    },
    async runDocumentLinkChecks() {
      if (this.documentGuid && await this.validate()) {
        this.isDocumentLinkValid = 'true'
        const shouldOpenInDataView = await this.$store.dispatch('workflow/shouldOpenInDataView', this.documentGuid)
        if (shouldOpenInDataView === true) {
          this.$router.push({ name: 'DocumentData', params: { documentID: this.documentGuid } })
        } else {
          this.$router.push({ name: 'Document', params: { documentID: this.documentGuid } })
        }
      } else {
        this.isDocumentLinkValid = 'false'
      }
    },
    async validate() {
      let isValid = true

      if (await testIsGuid(this.documentGuid)) {
        if (this.encryptedUserName && this.encryptedUserName !== '') {
          await this.authenticateDefaultLinkUser()
          if (!this.isSignedIn) {
            this.errors.push('Unable to authenticate default link user.')
            isValid = false
          }
        }
      } else {
        this.errors.push('The document ID was not a valid GUID.')
        isValid = false
      }

      if (!isValid) {
        console.warn(this.errors)
      }

      return isValid
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  height: 100vh;
}
.errors {
  color: darkred;;
}
</style>